<!--
Error container =>  error container if !tool has been added/tracked {
  content => required {
    title
    subtitle
  }
  showError => required
  action => !required {
    click => run this function on click
    label => label for action path
  }
}
-->

<template>
<div v-if="showError" class="error-container scale-in-ver-top">
  <br>
  <h3>{{content.title}}</h3>
  <small>{{content.subtitle}}</small>
  <span v-if="action">
    <br>
    <v-btn
      @click="buttonClick"
      color="#039c88"
      class="mt-5"
      dark>
      {{actionLabel}}
    </v-btn>
  </span>
</div>
</template>

<script>
export default {
  name: 'errorContainer',
  props: {
    showError: {
      type: Boolean,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: false,
    },
  },
  computed: {
    actionLabel () {
      return this.action.label;
    },
  },
  methods: {
    buttonClick () {
      this.action.click();
    },
  },
};
</script>
