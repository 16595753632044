var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showError
    ? _c("div", { staticClass: "error-container scale-in-ver-top" }, [
        _c("br"),
        _c("h3", [_vm._v(_vm._s(_vm.content.title))]),
        _c("small", [_vm._v(_vm._s(_vm.content.subtitle))]),
        _vm.action
          ? _c(
              "span",
              [
                _c("br"),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-5",
                    attrs: { color: "#039c88", dark: "" },
                    on: { click: _vm.buttonClick }
                  },
                  [_vm._v("\n      " + _vm._s(_vm.actionLabel) + "\n    ")]
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }