var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.therapyGroup
    ? _c(
        "v-layout",
        { attrs: { "align-center": "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _vm.hasTherapyGroup
                ? _c("element-view", {
                    attrs: { therapyGroup: _vm.therapyGroup },
                    on: { completedGroup: _vm.completeGroup }
                  })
                : _c("error-container", {
                    staticClass: "mt-2 mb-4 text-center px-1",
                    attrs: {
                      content: {
                        title: _vm.$t("app.products.empty.title"),
                        subtitle: _vm.$t("app.products.empty.subtitle")
                      },
                      showError: !_vm.hasTherapyGroup
                    }
                  })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }